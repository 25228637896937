import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH } from 'constants/sizes';

import { Container as UIContainer, Picture as UIPicture, Text } from '../Slide/styled';

export const Title = styled.h1`
  grid-row: 1;
  grid-column: 1/-2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const TextOne = styled(Text)`
  grid-row: 4;
  grid-column: 7/9;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-2;
  }
`;

export const TextTwo = styled(Text)`
  grid-row: 4;
  grid-column: 9/-1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 5;
    grid-column: 1/-2;
  }
`;

export const Space = styled.div`
  grid-row: 3;
  grid-column: 1/-1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: none;
  }
`;

export const Container = styled(UIContainer)``;

export const Picture = styled(UIPicture)`
  will-change: transform;
  transition: transform 0.05s;
`;
