import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import Slides from './Slides';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "DoDeepTech"}, page: {eq: "technology"}) {
      title
      strapiId
      slide {
        header
        id
        title
        media {
          ...MediaImageFragment
        }
        text {
          text
        }
      }
    }
  }
`;

interface IDoDeepTech {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const DoDeepTech: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IDoDeepTech) => data?.strapiWidgets && (
      <Slides {...data.strapiWidgets} />
    )}
  />
);

export default DoDeepTech;
