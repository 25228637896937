import styled from '@emotion/styled';

import {
  MIN_4K,
  MIN_2K,
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
  MOBILE_TOP_MENU_HEIGHT,
} from 'constants/sizes';
import {
  Layout, MiddleText, GatsbyPicture, Video as UIVideo,
} from 'UI';

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  background: #0d0b0f;
`;

export const Wrapper = styled(Layout)`
  min-height: 100vh;
  padding-top: 100px;
  position: relative;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    align-content: end;
    row-gap: 45px;
  }
`;

export const WrapperSecond = styled(Wrapper)`
  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    align-content: start;
  }
`;

export const TitleWrapper = styled.div`
  top: 0;
  grid-column: 1/4;
  height: 200%;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    height: auto;
    grid-row: 1;
    grid-column: 1/-1;
    align-self: end;
  }
`;

export const Title = styled.h3`
  display: block;
  position: sticky;
  white-space: pre-wrap;
  color: #000;
  height: auto;
  padding-right: 0;

  top: 100px;
  left: 80px;
  bottom: 100px;
  transition: color 0.3s linear;

  &.white {
    color: #fff;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    left: 48px;
    position: static;
    align-self: end;
    color: #fff;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    left: 20px;

    top: ${MOBILE_TOP_MENU_HEIGHT}px;
  }

  @media (min-width: ${MIN_2K}px) {
    left: 120px;
  }

  @media (min-width: ${MIN_4K}px) {
    left: 240px;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: -1;

  > source,
  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const VideoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

interface IVideo {
  type: string;
}

export const Video = styled(UIVideo)<IVideo>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  min-height: 250px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    height: 40%;
    transform: scale(1.5);
  }
`;

export const TextOne = styled.p`
  grid-column: 7/-1;
  color: #fff;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 3/-1;
    grid-row: 2;
    align-self: end;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-column: 6/-1;
  }
`;

export const TextTwo = styled(MiddleText)`
  grid-column: 5/9;
  color: #000;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-column: 5/10;
  }
`;
