import styled from '@emotion/styled';

import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import { GatsbyPicture, Layout, SectionTitle } from 'UI';

export const Container = styled(Layout)`
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background: #000;
  grid-template-rows: auto auto 1fr auto auto;

  color: #fff;
`;

export const Space = styled.div`
  grid-row: 3;
  grid-column: 1/-1;
`;

export const Header = styled(SectionTitle)`
  grid-row: 1;
  grid-column: 1/-1;
  align-self: start;
`;

export const Title = styled.h3`
  grid-row: 2;
  grid-column: 1/6;
  align-self: start;
`;

export const Text = styled.p`
  grid-row: 4;
`;

export const TextLeft = styled(Text)`
  grid-column: 1/5;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-column: 5/8;
  }

  @media (min-width: ${MIN_4K}px) {
  }
`;

export const TextRight = styled(Text)`
  grid-column: 5/9;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/7;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 5;
    grid-column: 1/-1;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-column: 8/11;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  z-index: -1;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  > img,
  > source {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ::before {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    height: 70.42%;
    top: 50%;
    transform: translateY(-50%);

    ::before {
      content: '';
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #000000);
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    width: 150%;
    left: auto;
    right: 0;
    top: 55%;

    > img,
    > source {
      object-fit: scale-down;
    }
  }
`;
