import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH } from 'constants/sizes';

import { Container as UIContainer, Title as UITitle, Picture } from '../Slide/styled';

export const Title = styled(UITitle)`
  grid-row: 1;
  grid-column: 1/7;
`;

export const Container = styled(UIContainer)`
  @media (min-width: ${MAX_TABLET_WIDTH}px) {
    ${Picture} {
      position: absolute;
      top: 115px;
      bottom: 0;
      left: auto;
      right: 0;
      width: 62.5%;
    }
  }
`;
