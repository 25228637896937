import React, { forwardRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  SlideTitle, Picture, Content, SliderContent, Background,
} from './styled';

interface ISlideItem extends IComponentWidgetSlide<IMediaImage> {
  index: number;
}

const SlideItem = forwardRef<HTMLDivElement, ISlideItem>(
  ({
    title, media, index,
  }, ref) => (
    <Content ref={ref} className={index === 0 ? 'active' : ''}>
      <SliderContent withPaddings>
        <SlideTitle>{title}</SlideTitle>
      </SliderContent>
      <Background>
        <Picture {...media} />
      </Background>
    </Content>
  ),
);

export default SlideItem;
