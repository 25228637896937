import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { ScaleVisible } from 'UI';

import {
  Container, Title, Text, Picture,
} from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "addon"}, page: {eq: "technology"}) {
      title
      header
      text {
        text
      }
      media {
        ...MediaImageFragment
      }
    }
  }
`;

interface IAddOnHolographic {
  strapiWidgets: IWidget<IMediaImage, null>;
}

const AddOnHolographic: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IAddOnHolographic) => {
      if (!data?.strapiWidgets) return null;

      const { strapiWidgets } = data;
      const { text, header, media } = strapiWidgets;

      return (
        <ScaleVisible>
          <Container>
            <Title>{header}</Title>
            <Text>{text[0]?.text}</Text>

            <Picture
              {...media?.[0]}
              title={header}
            />
          </Container>
        </ScaleVisible>
      );
    }}
  />
);

export default AddOnHolographic;
