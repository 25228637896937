import React, { forwardRef } from 'react';

import { Content, Container, Picture } from '../Slide/styled';
import { ISlideItem } from '../types';

import {
  Title, TextOne, TextTwo, TextThree, TextFour, Space, TextWrapper,
} from './styled';

const SlideItem = forwardRef<HTMLDivElement, ISlideItem>(
  ({
    title, text, media,
  }, ref) => (
    <Container ref={ref}>
      <Content>
        <Title>{title}</Title>
        <TextOne>{text[0]}</TextOne>

        <Space />

        <TextWrapper>
          <TextTwo>{text[1]}</TextTwo>
          <TextThree>{text[2]}</TextThree>
          <TextFour>{text[3]}</TextFour>
        </TextWrapper>
      </Content>
      <Picture {...media} />
    </Container>
  ),
);

export default SlideItem;
