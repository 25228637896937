import styled from '@emotion/styled';

import {
  Wrapper as MenuWrapper,
  SliderMenuLink,
} from 'UI/ScrollableSlider/SliderMenu/styled';
import { MAX_MOB_WIDTH, MOBILE_TOP_MENU_HEIGHT } from 'constants/sizes';

export const SlidesList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
`;

export const Container = styled.div`
  position: relative;
  z-index: 1;
  background: #000;
  color: #fff;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: -${MOBILE_TOP_MENU_HEIGHT}px;
  }

  ${MenuWrapper} {
    > ${SliderMenuLink}:nth-of-type(1) {
      display: none;

      &.active ~ * {
        transition: opacity 0.4s ease-in-out;
        opacity: 0;
      }

      &.active + * {
        color: var(--red);
      }
    }
  }
`;
