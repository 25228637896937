import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Container,
  Header,
  Wrapper,
  // Slider styles
  FirstSlide,
  SecondSlide,
  ThirdSlide,
  FourthSlide,
  FifthSlide,
} from './styled';
import Slide from './Slide/Slide';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "inhousefacilities"}, page: {eq: "technology"}) {
      title
      header
      id
      strapiId
      slide {
        id
        title
        header
        media {
          ...MediaImageFragment
        }
        text {
          text
        }
      }
    }
  }
`;

interface IFacilities {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const Facilities: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IFacilities) => {
      if (!data?.strapiWidgets) return null;

      const { slide = [], header } = data.strapiWidgets;

      return (
        <Wrapper>
          <Header>{header}</Header>
          <Container count={slide.length}>
            <Slide {...slide[0]} styles={FirstSlide} />
            <Slide {...slide[1]} styles={SecondSlide} />
            <Slide {...slide[2]} styles={ThirdSlide} />
            <Slide {...slide[3]} styles={FourthSlide} />
            <Slide {...slide[4]} styles={FifthSlide} />
          </Container>
        </Wrapper>
      );
    }}
  />
);

export default Facilities;
