import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH } from 'constants/sizes';

import {
  Container as UIContainer,
  Title as UITitle,
  Content,
  Picture,
  Text,
} from '../Slide/styled';

export const Title = styled(UITitle)`
  grid-row: 1;
  grid-column: 1/6;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const TextOne = styled(Text)`
  grid-row: 2;
  grid-column: 1/4;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Container = styled(UIContainer)`
  ${Content} {
    grid-template-rows: auto;
    align-content: space-between;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      align-content: end !important;
    }
  }

  @media (min-width: ${MAX_TABLET_WIDTH}px) {
    ${Picture} {
      top: 115px;
    }
  }
`;
