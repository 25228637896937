import React, { forwardRef } from 'react';

import { Content, Picture } from '../Slide/styled';
import { ISlideItem } from '../types';

import { Container, Title, TextOne } from './styled';

const SlideItem = forwardRef<HTMLDivElement, ISlideItem>(
  ({
    title, text, media,
  }, ref) => (
    <Container ref={ref}>
      <Content>
        <Title>{title}</Title>
        <TextOne>{text[0]}</TextOne>
      </Content>
      <Picture {...media} />
    </Container>
  ),
);

export default SlideItem;
