import styled from '@emotion/styled';

import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';

import { Title as UITitle, Text } from '../Slide/styled';

export const Title = styled(UITitle)`
  grid-row: 1;
  grid-column: 1/6;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const TextOne = styled(Text)`
  grid-row: 2;
  grid-column: 1/3;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-2;
  }
`;

export const Description = styled(Text)`
  border-left: 1px solid var(--red);
  padding-left: 20px;
  height: fit-content;
  max-width: 66%;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/-2;
    max-width: 100%;
  }

  @media (min-width: ${MIN_2K}px) {
    border-width: 2px;
    padding-left: 40px;
  }

  @media (min-width: ${MIN_4K}px) {
    border-width: 4px;
    padding-left: 80px;
  }
`;

export const TextTwo = styled(Description)`
  grid-column: 1/4;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const TextThree = styled(Description)`
  grid-column: 4/7;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 5/7;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const TextFour = styled(Description)`
  grid-column: 7/10;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/4;
    grid-row: 2;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 2;
    grid-column: 1/-1;
  }
`;

export const Space = styled.div`
  grid-row: 3;
  grid-column: 1/-1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  grid-row: 4;
  grid-column: 1/-1;
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(9, minmax(0, 1fr));

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 3;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    margin-top: 20px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (max-width: 540px) {
    grid-column: 1/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) and (max-height: 720px) {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    gap: 0;

    > * {
      scroll-snap-align: start;
      flex-shrink: 0;
      width: calc(100vw - 100px);
      margin: 0 20px;
    }
  }
`;
