import React, { forwardRef } from 'react';

import { Content, Picture } from '../Slide/styled';
import { ISlideItem } from '../types';

import { Title, Container } from './styled';

const SlideItem = forwardRef<HTMLDivElement, ISlideItem>(({
  title, media,
}, ref) => (
  <Container ref={ref}>
    <Content>
      <Title>{title}</Title>
    </Content>
    <Picture {...media} />
  </Container>
));

export default SlideItem;
