import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';

import { GatsbyPicture, Intersection, MiddleText } from 'UI';
import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';

const textStyle = css`
  opacity: 0;
  transform: translateY(-50px);
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
  will-change: opacity, transform;
`;

export const Title = styled(MiddleText)`
  color: var(--red);
  grid-column: 1/-1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: inline;
    font-size: 14px;
    line-height: 18px;

    ::after {
      content: ' ';
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    font-size: 18px;
    line-height: 22px;
    width: 50%;
  }
`;

export const Text = styled(MiddleText)`
  grid-column: 1/-1;
  margin-top: 0.4em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: inline;
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    width: 50%;
    margin-top: 0;
  }
`;

export const Description = styled.div`
  ${textStyle}

  margin-top: 30px;
  grid-row: 2;
  min-height: 11em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-left: 48px;
    padding-right: 48px;
    min-height: auto;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    gap: 1.5em;
  }

  @media (min-width: ${MIN_2K}px) {
    margin-top: 70px;
  }

  @media (min-width: ${MIN_4K}px) {
    margin-top: 140px;
  }
`;

export const ImageContainer = styled.div`
  grid-column: 1/-1;
  grid-row: 1;
  width: 100%;
  align-self: stretch;
  position: relative;
  overflow: hidden;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    height: 0;
    padding-bottom: 64.5%;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transform: scale(1.2);
  clip-path: inset(0 0 100% 0);
  transform-origin: top center;
  will-change: clip-path, transform;
  transition: clip-path 0.8s ease-in-out, transform 0.8s ease-in-out;

  > img,
  > source {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    position: absolute;
  }
`;

interface IContainer {
  styles: SerializedStyles;
}

export const Container = styled(Intersection)<IContainer>`
  ${({ styles }) => styles}
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-auto-flow: column;
  height: 100%;

  &.active {
    ${Picture} {
      transform: scale(1);
      clip-path: inset(0 0 0% 0);
    }

    ${Title},
    ${Description} {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;
