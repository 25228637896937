import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { ScaleVisible } from 'UI';

import {
  Container, Picture, Title, Header, TextLeft, TextRight, Space,
} from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "embedded"}, page: {eq: "technology"}) {
      title
      header
      strapiId
      text {
        text
      }
      media {
        ...MediaImageFragment
      }
    }
  }
`;

interface IEmbedded {
  strapiWidgets: IWidget<IMediaImage, null>;
}

const Embedded: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IEmbedded) => {
      if (!data?.strapiWidgets) return null;

      const { strapiWidgets } = data;
      const { text, header, media } = strapiWidgets;

      return (
        <ScaleVisible>
          <Container withPaddings>
            <Header>{header}</Header>
            <Title>{text?.[0].text}</Title>

            <Space />

            <TextLeft>{text?.[1].text}</TextLeft>
            <TextRight>{text?.[2].text}</TextRight>

            <Picture {...media?.[0]} title={header} />
          </Container>
        </ScaleVisible>
      );
    }}
  />
);

export default Embedded;
