import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaVideo } from 'interfaces/media';

import {
  Head, Title, Wrapper, Video, Text,
} from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "ARRE"}, page: {eq: "technology"}) {
      title
      header
      strapiId
      text {
        text
      }
      media {
        ...MediaVideoFragment
      }
    }
  }
`;

interface IARExperience {
  strapiWidgets: IWidget<IMediaVideo, null>;
}

const ARExperience: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IARExperience) => {
      if (!data?.strapiWidgets) return null;

      const { strapiWidgets } = data;
      const { text, header, media } = strapiWidgets;
      const {
        FHD, HD, qHD, videoScreenshots,
      } = media?.[0]?.localFile;

      const srcSet = {
        1080: FHD?.path,
        720: HD?.path,
        540: qHD?.path,
      };

      return (
        <>
          <Wrapper withPaddings>
            <Head>{header}</Head>
            <Title>{text?.[0].text}</Title>
            <Text>{text?.[1].text}</Text>
          </Wrapper>
          <Video
            poster={videoScreenshots?.[0].publicURL}
            src={FHD?.path}
            srcSet={srcSet}
            type="video/mp4"
            loop
          />
        </>
      );
    }}
  />
);

export default ARExperience;
