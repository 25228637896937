import styled from '@emotion/styled';

import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import { Layout } from 'UI';

export const Container = styled(Layout)`
  margin: 6em 0;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    row-gap: 90px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    row-gap: 60px;
  }
`;

export const Title = styled.h3`
  grid-column: 1/4;

  span {
    display: block;
    position: sticky;
    top: 2em;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const AdvantagesList = styled.div`
  grid-column: 6/-1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 130px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
    grid-row-gap: 80px;
    grid-column-gap: 60px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row-gap: 40px;
    grid-column-gap: 20px;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-column-gap: 180px;
    grid-row-gap: 140px;
  }

  @media (min-width: ${MIN_4K}px) {
    grid-column-gap: 360px;
    grid-row-gap: 280px;
  }
`;
