import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import { GatsbyPicture, Layout } from 'UI';

const contentAppear = keyframes`
  from  { visibility: hidden; }
  to    { visibility: visible; }
`;

const textIn = keyframes`
  from  { opacity: 0; }
  20%   { opacity: 0; }
  to    { opacity: 1; }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: opacity 0.2s;
  will-change: opacity, visibility;

  > img,
  > source {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 620px;
    max-height: 50%;

    ::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0) 58.2%, #000000 100%);
    }
  }
`;

export const Title = styled.h3``;

export const Text = styled.p``;

export const Content = styled(Layout)`
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: 0.3s ease-in-out;
  will-change: opacity, visibility;
  top: 0;
  left: 0;
  height: 100%;
  align-content: flex-start;
  grid-template-rows: auto auto 1fr auto;
  row-gap: 30px;

  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-bottom: 50px;
    align-content: flex-end;
    grid-template-rows: auto;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-top: 175px;
    padding-bottom: 120px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-top: 320px;
    padding-bottom: 240px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-delay: 0.6s;
  will-change: visibility;

  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;

    background: #000;
  }

  :not(.active) {
    animation: ${contentAppear} 0s ease-out;
    animation-delay: 1s;
    animation-direction: reverse;
    animation-fill-mode: forwards;

    ${Picture} {
      display: none;
    }
  }

  &.active {
    animation: ${contentAppear} 0s ease-out;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    z-index: -1;

    ${Picture} {
      display: block;
    }

    ${Content} {
      visibility: visible;
      animation: ${textIn} 1s ease-in-out;
      opacity: 1;
    }
  }
`;
