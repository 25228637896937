import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaSvg } from 'interfaces/media';

import Slide from './Slide/Slide';

import { Title, Container, AdvantagesList } from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "advantages"}, page: {eq: "technology"}) {
      title
      header
      strapiId
      slide {
        id
        title
        header
        text {
          text
        }
        media {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

interface IAdvantages {
  strapiWidgets: IWidget<null, IMediaSvg>;
}

const Advantages: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IAdvantages) => {
      if (!data?.strapiWidgets) return null;

      const { slide, header } = data.strapiWidgets;

      return (
        <Container withPaddings>
          <Title>
            <span>{header}</span>
          </Title>
          <AdvantagesList>
            {slide.map((item) => (
              <Slide key={`slide_${item.id}`} {...item} />
            ))}
          </AdvantagesList>
        </Container>
      );
    }}
  />
);

export default Advantages;
