import React, { FC } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaSvg } from 'interfaces/media';

import {
  Container, Title, Text, Image,
} from './styled';

const Slide: FC<IComponentWidgetSlide<IMediaSvg>> = ({ title, header, media }) => (
  <Container>
    <Image src={media.localFile.publicURL || ''} />
    <Title>{title}</Title>
    <Text>{header}</Text>
  </Container>
);

export default Slide;
