import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { NextLink } from 'UI';

import {
  Container, Title, TextLink, Picture,
} from './style';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "curiousaboutteam"}, page: {eq: "technology"}) {
      title
      id
      strapiId
      header
      media {
        ...MediaImageFragment
      }
    }
  }
`;

interface ICurious {
  strapiWidgets: IWidget<IMediaImage, null>;
}

const CuriousAboutTeam: FC = () => (
  <StaticQuery
    query={query}
    render={(data: ICurious) => {
      if (!data?.strapiWidgets) return null;

      const { media, header } = data.strapiWidgets;

      return (
        <Container>
          <Title>{header}</Title>
          <TextLink>
            <NextLink path="/culture/">
              Learn more
              <br />
              about our culture
            </NextLink>
          </TextLink>
          <Picture {...media?.[0]} />
        </Container>
      );
    }}
  />
);

export default CuriousAboutTeam;
