import styled from '@emotion/styled';

import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';

import { GatsbyPicture, Layout, NextText } from 'UI';

export const Container = styled(Layout)`
  color: var(--white);
  background: var(--black);

  min-height: 100vh;
  align-content: start;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 0;
  row-gap: 30px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-bottom: 50px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-top: 175px;
    padding-bottom: 120px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-top: 320px;
    padding-bottom: 240px;
  }
`;

export const Title = styled.h3`
  grid-column: 5/-1;
  grid-row: 1;

  @media (min-width: ${MIN_2K}px) {
    grid-column: 6/-1;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/6;
  }
`;

export const TextLink = styled(NextText)`
  grid-column: 5/-1;
  grid-row: 2;

  @media (min-width: ${MIN_2K}px) {
    grid-column: 6/-1;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/6;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  z-index: -1;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  > img,
  > source {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 71.09%);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    align-self: center;

    ::before {
      background: linear-gradient(#000000 0%, rgba(0, 0, 0, 0) 71.09%);
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
  }
`;
