import React, {
  useRef, createRef, FC, useEffect,
} from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { ScrollableSlider } from 'UI';

import SlideItem from './Slide/Slide';

import { Container, SlidesList } from './styled';

interface ITechSlider {
  slides: IComponentWidgetSlide<IMediaImage>[];
}

const TechSlider: FC<ITechSlider> = ({ slides }) => {
  const sliderRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>();

  /**
   * Slides refs
   */
  const slideRefs = useRef(slides.map(() => createRef<HTMLDivElement>()));

  let activeSlide = 0;
  const slidesList = slides.map(({ title }) => title);

  const setActiveClass = (slide: number) => {
    const nodes = slideRefs.current;

    if (!nodes) return;

    nodes.forEach(({ current }, index) => (index === slide ? current?.classList.add('active') : current?.classList.remove('active')));
  };

  const handleChangeSlide = (slide: number = activeSlide) => {
    activeSlide = slide;

    setActiveClass(slide);
  };

  const handleVisibility = (visibility: boolean) => {
    if (visibility) handleChangeSlide(activeSlide);
  };

  useEffect(() => {
    if (containerRef.current) setActiveClass(0);
  }, []);

  setActiveClass(0);

  return (
    <Container ref={containerRef}>
      <ScrollableSlider
        ref={sliderRef}
        items={slidesList}
        onChangeSlide={handleChangeSlide}
        onChangeVisibility={handleVisibility}
        appear="scaleVisible"
      >
        <SlidesList>
          {slides.map((item, index) => (
            <SlideItem
              {...item}
              key={`slider-${item.id}`}
              ref={slideRefs.current[index]}
              index={index}
            />
          ))}
        </SlidesList>
      </ScrollableSlider>
    </Container>
  );
};

export default TechSlider;
