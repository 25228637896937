import React, { FC, useRef } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx';

import { IComponentWidgetSlide, IWidget } from 'interfaces/widgets';
import { IMediaImage, IMediaVideo } from 'interfaces/media';

import { Intersection } from 'UI';

import {
  Container,
  Wrapper,
  WrapperSecond,
  TitleWrapper,
  Title,
  TextOne,
  TextTwo,
  Picture,
  Video,
  VideoWrapper,
} from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "renderingengine"}, page: {eq: "technology"}) {
      title
      header
      strapiId
      slide {
        id
        title
        header
        text {
          text
        }
        media {
          localFile {
            ...ImageFragment
            ...VideoFragment
          }
          alternativeText
          id
        }
      }
    }
  }
`;

interface IARRenderingEngine {
  strapiWidgets: IWidget<null, IMediaVideo | IMediaImage>;
}

const ARRenderingEngine: FC = () => {
  const titleRef = useRef<HTMLHeadingElement>();

  const setColor = ({ visibility }): void => {
    if (visibility) {
      titleRef.current.classList.add('white');
    } else {
      titleRef.current.classList.remove('white');
    }
  };

  const getVideoSrcSet = (media: IMediaVideo) => ({
    1080: media.localFile.FHD.path,
    540: media.localFile.qHD.path,
  });

  return (
    <StaticQuery
      query={query}
      render={(data: IARRenderingEngine) => {
        if (!data?.strapiWidgets) return null;

        const { slide, header } = data.strapiWidgets;

        const slideOne = slide[0] as IComponentWidgetSlide<IMediaVideo>;
        const slideTwo = slide[1] as IComponentWidgetSlide<IMediaImage>;

        if (!slideOne || !slideTwo) return null;

        return (
          <Container>
            <Wrapper withPaddings>
              <TitleWrapper>
                <Title ref={titleRef}>
                  <Markdown>{header}</Markdown>
                </Title>
              </TitleWrapper>
              <Intersection options={{ threshold: 0.0, rootMargin: '-150px 0px' }} way={setColor}>
                <VideoWrapper>
                  <Video
                    preload="auto"
                    loop
                    playsInline
                    muted
                    poster={slideOne.media.localFile.videoScreenshots?.[0].publicURL}
                    src={getVideoSrcSet(slideOne.media)[1080]}
                    srcSet={getVideoSrcSet(slideOne.media)}
                    type={`video/${slideOne.media.extension || 'mp4'}`}
                  />
                </VideoWrapper>
              </Intersection>
              <TextOne>{slideOne.header}</TextOne>
            </Wrapper>

            <WrapperSecond>
              <Picture {...slideTwo.media} />
              <TextTwo>{slideTwo.header}</TextTwo>
            </WrapperSecond>
          </Container>
        );
      }}
    />
  );
};

export default ARRenderingEngine;
