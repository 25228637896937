import React, { forwardRef, useRef, useEffect } from 'react';

import throttle from 'utils/throttle';

import { Content } from '../Slide/styled';
import { ISlideItem } from '../types';

import {
  Container, Title, TextOne, TextTwo, Space, Picture,
} from './styled';

const SlideItem = forwardRef<HTMLDivElement, ISlideItem>(
  ({
    title, text, media,
  }, ref: { current: HTMLDivElement }) => {
    const pictureRef = useRef<HTMLPictureElement>();

    const handleCheckScaleOne = () => {
      const height = window.innerHeight;

      if (!height || !pictureRef.current) return;

      const top = window.scrollY || 0;

      if (height < top) return;

      let multiplier = 1;

      if (top > 0) multiplier = 1 - top / height;

      const scale = multiplier < 1.4 ? 1.0 + 0.12 * multiplier : 1;

      pictureRef.current.style.transform = `scale(${scale})`;
    };

    const handleResizeScroll = () => {
      throttle(handleCheckScaleOne)();
    };

    const handler = (entries: IntersectionObserverEntry[]) => {
      const { isIntersecting } = entries[0];

      if (isIntersecting) {
        document.removeEventListener('scroll', handleResizeScroll);
        document.addEventListener('scroll', handleResizeScroll, true);
      } else {
        document.removeEventListener('scroll', handleResizeScroll);
      }
    };

    useEffect(() => {
      handleCheckScaleOne();

      if (typeof IntersectionObserver !== 'undefined' && pictureRef.current) {
        const options = {
          threshold: 0.0,
        };
        const observer = new IntersectionObserver(handler, options);
        observer.observe(pictureRef.current);
      }

      return () => document.removeEventListener('scroll', handleCheckScaleOne);
    }, []);

    return (
      <Container ref={ref}>
        <Content>
          <Title>{title}</Title>
          <Space />
          <TextOne>{text[0]}</TextOne>
          <TextTwo>{text[1]}</TextTwo>
        </Content>
        <Picture {...media} ref={pictureRef} />
      </Container>
    );
  },
);

export default SlideItem;
