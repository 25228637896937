import styled from '@emotion/styled';

import { MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';
import {
  Layout, MiddleText, SectionTitle, Video as UIVideo,
} from 'UI';

export const Head = styled(SectionTitle)`
  grid-column: 1/-1;
  grid-row: 1;
`;

export const Title = styled.h3`
  grid-column: 1/7;
  grid-row: 2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Text = styled(MiddleText)`
  grid-column: 7/-1;
  grid-row: 3;

  @media (min-width: ${MIN_2K}px) {
    grid-column: 8/-1;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Video = styled(UIVideo)`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    filter: drop-shadow(10px 0 0 black) drop-shadow(-10px 0 0 black);
  }
`;

export const Wrapper = styled(Layout)`
  padding-bottom: 122px;
  row-gap: 50px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-bottom: 110px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    row-gap: 40px;
    padding-bottom: 40px;
  }
`;
