import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';
import { Layout, GatsbyPicture } from 'UI';

export const SlideTitle = styled.h3`
  color: var(--white);
  grid-column: 1/-2;
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;

  > img,
  > source {
    object-fit: cover;
    height: 100%;
  }
`;

const contentAppear = keyframes`
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
`;

const textIn = keyframes`
  0%    { opacity: 0; }
  20%   { opacity: 0; }
  100%  { opacity: 1; }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: opacity 0.2s;
  will-change: opacity, visibility;

  @media (max-width: ${MAX_TABLET_WIDTH}px) and (orientation: portrait) {
    height: 60vh;
    top: auto;
    bottom: 0;
  }
`;

export const SliderContent = styled(Layout)`
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: 0.3s ease-in-out;
  will-change: opacity, visibility;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 75px;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation-delay: 0.6s;
  will-change: visibility;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;

    background: #000;
  }

  :not(.active) {
    animation: ${contentAppear} 0s ease-out;
    animation-delay: 1s;
    animation-direction: reverse;
    animation-fill-mode: forwards;

    ${Background} {
      display: none;
    }
  }

  &.active {
    animation: ${contentAppear} 0s ease-out;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    z-index: -1;

    ${Background} {
      display: block;
    }

    ${SliderContent} {
      visibility: visible;
      animation: ${textIn} 1s ease-in-out;
      opacity: 1;
    }
  }
`;
