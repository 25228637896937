import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import Slides from './Slides';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "addonSlider"}, page: {eq: "technology"}) {
      title
      strapiId
      slide {
        header
        id
        title
        media {
          ...MediaImageFragment
        }
        text {
          text
        }
      }
    }
  }
`;

interface ITechSlider {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const TechSlider: FC = () => (
  <StaticQuery
    query={query}
    render={(data: ITechSlider) => {
      if (!data?.strapiWidgets) return null;

      return (
        <Slides slides={data.strapiWidgets.slide} />
      );
    }}
  />
);

export default TechSlider;
