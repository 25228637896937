import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { IQueryPage } from 'interfaces/page';

import { setActiveBlock, setPreviousBlock } from 'store/actions';

import Head from 'components/Layout/Head/Head';

import CuriousAboutTeam from 'components/blocks/TechnologyPage/CuriousAboutTeam/CuriousAboutTeam';
import Facilities from 'components/blocks/TechnologyPage/Facilities/Facilities';
import AddOn from 'components/blocks/TechnologyPage/AddOn/AddOn';
import AddOnHolographic from 'components/blocks/TechnologyPage/AddOnHolographic/AddOnHolographic';
import Advantages from 'components/blocks/TechnologyPage/Advantages/Advantages';
import Embedded from 'components/blocks/TechnologyPage/Embedded/Embedded';
import ARRenderingEngine from 'components/blocks/TechnologyPage/ARRenderingEngine/ARRenderingEngine';
import ARExperience from 'components/blocks/TechnologyPage/ARExperience/ARExperience';
import DoDeepTech from 'components/blocks/TechnologyPage/DoDeepTech/DoDeepTech';
import { Intersection } from 'UI';

const TechnologyPage: FC = () => {
  const dispatch = useDispatch();

  const setBlock = ({ title, visibility }: ISetBlock) => {
    if (visibility) {
      dispatch(setActiveBlock(`#${title}`));
    } else {
      dispatch(setPreviousBlock(`#${title}`));
    }
  };

  return (
    <>
      <Intersection id="dodeeptech" way={setBlock}>
        <DoDeepTech />
      </Intersection>

      <Intersection id="embedded" way={setBlock}>
        <Embedded />
      </Intersection>

      <Intersection id="arre" way={setBlock}>
        <ARExperience />

        <Advantages />

        <ARRenderingEngine />
      </Intersection>

      <Intersection id="addon" way={setBlock}>
        <AddOnHolographic />

        <AddOn />
      </Intersection>

      <Intersection id="inHouseFacilities" way={setBlock}>
        <Facilities />

        <CuriousAboutTeam />
      </Intersection>
    </>
  );
};

const query = graphql`
  query {
    page: strapiTechnologyPage {
      meta {
        ...StrapiMetaFragment
      }
    }
  }
`;

const TechnologyPageQuery: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryPage) => {
      const siteInfo = data.page.meta;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            preview={siteInfo.preview}
            slug="technology"
          />
          <TechnologyPage />
        </>
      );
    }}
  />
);

export default TechnologyPageQuery;
