import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import { Layout, SectionTitle } from 'UI';

export const Wrapper = styled(Layout)`
  margin-top: 40px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Header = styled(SectionTitle)`
  grid-column: 1/-1;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Container = styled.div<{ count: number }>`
  display: grid;
  grid-row: 2;
  grid-column: 1/-1;
  row-gap: 135px;
  column-gap: 80px;
  width: 100%;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, calc(100vh - 135px));
  height: ${({ count }) => (count - 1) * 100}vh;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    row-gap: 80px;
    grid-template-rows: auto;
    margin-bottom: 100px;
    height: auto;
  }

  @media (min-width: ${MIN_2K}px) {
    row-gap: 180px;
    column-gap: 120px;
    margin-bottom: 190px;
  }

  @media (min-width: ${MIN_4K}px) {
    row-gap: 360px;
    column-gap: 240px;
    margin-bottom: 260px;
  }
`;

export const FirstSlide = css`
  grid-row: 1;
  grid-column: 1/-1;
`;

export const SecondSlide = css`
  grid-row: 2;
  grid-column: 1/-1;
`;

export const ThirdSlide = css`
  grid-row: 3;
  grid-column: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const FourthSlide = css`
  grid-row: 3;
  grid-column: 2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 4;
    grid-column: 1/-1;
  }
`;

export const FifthSlide = css`
  grid-row: 4;
  grid-column: 1/-1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 5;
  }
`;
