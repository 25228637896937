import React, { FC, useRef } from 'react';
import { SerializedStyles } from '@emotion/react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Container, Wrapper, Picture, ImageContainer, Title, Text, Description,
} from './styled';

interface ISlider extends IComponentWidgetSlide<IMediaImage> {
  styles: SerializedStyles;
}

const Slider: FC<ISlider> = ({
  title, id, header, media, styles,
}) => {
  if (!id) return null;
  const containerRef = useRef<HTMLDivElement>();

  const handleAppear = ({ visibility }) => {
    if (visibility) {
      containerRef.current?.classList?.add('active');
    } else {
      containerRef.current?.classList?.remove('active');
    }
  };

  return (
    <Container options={{ threshold: 0.2, rootMargin: '0px' }} way={handleAppear} styles={styles}>
      <Wrapper ref={containerRef}>
        <ImageContainer>
          <Picture {...media} title={title} />
        </ImageContainer>

        <Description>
          <Title>{title}</Title>
          <Text>{header}</Text>
        </Description>
      </Wrapper>
    </Container>
  );
};

export default Slider;
