import React, {
  useRef, createRef, FC, useEffect,
} from 'react';

import { IComponentWidgetSlide, IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { ScrollableSlider } from 'UI';

import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo';
import SlideThree from './SlideThree/SlideThree';
import SlideZero from './SlideZero/SlideZero';

import { Container, SlidesList } from './styled';

const DoDeepTechSlides: FC<IWidget<null, IMediaImage>> = ({ slide }) => {
  const sliderRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>();

  /**
   * Slides refs
   */
  const slideRefs = useRef(slide.map(() => createRef<HTMLDivElement>()));

  let activeSlide = 0;
  const slidesList = slide.map(({ title }) => title);

  const setActiveClass = (item: number) => {
    const nodes = slideRefs.current;

    if (!nodes) return;

    nodes.forEach(({ current }, index) => (index === item ? current?.classList.add('active') : current?.classList.remove('active')));
  };

  const handleChangeSlide = (item: number = activeSlide) => {
    activeSlide = item;

    setActiveClass(item);
  };

  const handleVisibility = (visibility: boolean) => {
    if (visibility) handleChangeSlide(activeSlide);
  };

  useEffect(() => {
    if (containerRef.current) setActiveClass(0);
  }, []);

  setActiveClass(0);

  const getSlideData = (item: IComponentWidgetSlide<IMediaImage>) => ({
    title: item.header,
    text: item?.text.map(({ text }) => text) || [],
    media: item.media,
  });

  return (
    <Container ref={containerRef}>
      <ScrollableSlider
        ref={sliderRef}
        items={slidesList}
        onChangeSlide={handleChangeSlide}
        onChangeVisibility={handleVisibility}
      >
        <SlidesList>
          <SlideZero
            ref={slideRefs.current[0]}
            {...getSlideData(slide[0])}
            media={slide[1].media}
          />
          <SlideOne
            ref={slideRefs.current[1]}
            {...getSlideData(slide[1])}
          />
          <SlideTwo
            ref={slideRefs.current[2]}
            {...getSlideData(slide[2])}
          />
          <SlideThree
            ref={slideRefs.current[3]}
            {...getSlideData(slide[3])}
          />
        </SlidesList>
      </ScrollableSlider>
    </Container>
  );
};

export default DoDeepTechSlides;
