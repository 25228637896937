import styled from '@emotion/styled';

import { Container as MenuContainer } from 'UI/ScrollableSlider/SliderMenu/styled';
import { MAX_MOB_WIDTH } from 'constants/sizes';

export const SlidesList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #000;
`;

export const Container = styled.div`
  ${MenuContainer} {
    justify-content: end;
    right: 0;
    left: auto;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      width: 100%;
    }
  }
`;
